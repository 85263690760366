/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Iconify from 'src/components/iconify';
import { usePathname } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { useGetLikesCount } from 'src/api/connect';
import Badge from '@mui/material/Badge';
import { Client } from '@amityco/ts-sdk';
import { Typography, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import posthog from 'posthog-js';
import LoginViewModal from 'src/sections/auth/login-view-modal';
import { useBoolean } from 'src/hooks/use-boolean';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      {children}
    </Slide>
  );
}

type PathNumberDictionaryProps = {
  [key: string]: number;
};
const pathNumberDictionary: PathNumberDictionaryProps = {
  '/': 0,
  '/me/events/': 1,
  '/for-you/': 2,
  '/connects/': 3,
  '/messages/': 4,
};

export default function TabBar() {
  const [unreadCount, setUnreadCount] = useState(0);
  const [showPWA, setShowPWA] = useState(true);
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const loginModal = useBoolean();

  const pathname = usePathname();
  const { user } = useAuthContext();
  const { likesCounts } = useGetLikesCount(!!user);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const disposers: Amity.Unsubscriber[] = useMemo(() => [], []);

  const currentClient = Client.getActiveClient();
  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) setShowPWA(false);
  }, []);

  const onQueryChannel = useCallback(() => {
    const unsubscribe = Client.getUserUnread(({ data }) => {
      setUnreadCount(data.unreadCount);
    });
    disposers.push(unsubscribe);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClosePopup = useCallback(() => {
    setShowPWA(false);
    sessionStorage.setItem('showPWA', 'false');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentClient.sessionState === 'established') onQueryChannel();

    return () => {
      disposers.forEach((fn) => fn());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showThePopup: boolean = useMemo(
    () =>
      sessionStorage.getItem('showPWA')
        ? Boolean(sessionStorage.getItem('showPWA') === 'true')
        : showPWA,
    [showPWA]
  );

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10 }} elevation={3}>
          <Slide direction="up" in={showThePopup && isMobile} mountOnEnter unmountOnExit>
            <Stack
              alignItems="center"
              gap={2}
              sx={{
                position: 'absolute',
                bottom: 0,
                zIndex: 20,
                p: 3,
                width: '100%',
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                backgroundColor: '#000',
              }}
            >
              <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
                Get the full app experience
              </Typography>
              <Typography sx={{ fontSize: '15px' }}>
                Enjoy more options and great features on the app
              </Typography>
              <Button
                variant="contained"
                href="/tutorial"
                onClick={() => posthog.capture('add_to_homepage_tuto_clicked')}
                sx={{
                  width: '100%',
                  fontSize: '15px',
                  padding: '10px',
                }}
              >
                Add to home screen
              </Button>
              <Button variant="text" onClick={() => onClosePopup()} sx={{ fontSize: '15px' }}>
                Not now
              </Button>
            </Stack>
          </Slide>
          <BottomNavigation
            showLabels
            sx={{ height: 70, backgroundColor: '#000' }}
            value={pathNumberDictionary[pathname]}
          >
            <BottomNavigationAction
              label="Explore"
              href="/"
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              icon={<Iconify icon="carbon:explore" sx={{ height: 23, width: 23 }} />}
              onClick={() => posthog.capture('navbar_explore_clicked')}
            />
            <BottomNavigationAction
              label="Tickets"
              href="/me/events/?type=ticket"
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              icon={<Iconify icon="solar:ticket-bold" sx={{ height: 23, width: 23 }} />}
            />
            {/* <BottomNavigationAction
              label="For You"
              href="/for-you"
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              onClick={() => posthog.capture('navbar_foryou_clicked')}
              icon={<Iconify icon="heroicons:sparkles" sx={{ height: 23, width: 23 }} />}
            /> */}
            <BottomNavigationAction
              label="Connects"
              href="/connects"
              onClick={() => posthog.capture('navbar_connects_clicked')}
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              icon={
                <Badge badgeContent={likesCounts} invisible={!likesCounts} color="primary">
                  <Iconify icon="akar-icons:thunder" sx={{ height: 23, width: 23 }} />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="Chat"
              href="/messages"
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '0.5rem',
                '& .Mui-selected': { fontSize: '13px' },
                '& .MuiBottomNavigationAction-label': { fontSize: '13px' },
              }}
              onClick={() => posthog.capture('navbar_messages_clicked')}
              icon={
                <Badge badgeContent={unreadCount} invisible={unreadCount === 0} color="primary">
                  <Iconify icon="solar:chat-line-line-duotone" sx={{ height: 23, width: 23 }} />
                </Badge>
              }
            />
            {!user && (
              <BottomNavigationAction
                label="Login"
                sx={{ justifyContent: 'flex-start', paddingTop: '0.5rem' }}
                icon={<Iconify icon="eva:person-fill" sx={{ height: 23, width: 23 }} />}
                onClick={() => {
                  loginModal.onTrue();
                  posthog.capture('navbar_login_clicked');
                }}
              />
            )}
          </BottomNavigation>
        </Paper>
      </HideOnScroll>
      <LoginViewModal isOpen={loginModal.value} onClose={loginModal.onFalse} />
    </>
  );
}
