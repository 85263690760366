/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-ignore
import { ChannelRepository } from '@amityco/ts-sdk';

export const createChannel = async (senderId: number, receiverId: number) => {
  const newChannel = {
    type: 'conversation' as Amity.ChannelType,
    userIds: [senderId.toString(), receiverId.toString()],
  };

  const { data: channel } = await ChannelRepository.createChannel(newChannel);

  return channel;
};

export const deleteChannel = async (channelId: string) => {
  const response = await ChannelRepository.deleteChannel(channelId);

  return response;
};
