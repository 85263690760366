'use client';

import { useSearchParams, usePathname, useRouter, ReadonlyURLSearchParams } from 'next/navigation';
import { useCallback, useState } from 'react';
import { Stack } from '@mui/system';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { IEvent } from 'src/types/events';
import { Fab, Typography } from '@mui/material';
import posthog from 'posthog-js';
import Iconify from 'src/components/iconify';
import { AnimatePresence, m } from 'framer-motion';
import { useQueryState } from 'nuqs';
import EventList from './event-list';
import LiveEventsDesktop from './live-events-desktop';
import MapView from '../map-view/map-view';

interface ScrollTopButton {
  window?: () => Window;
  children: React.ReactElement;
}
interface IEventsViewProps {
  events: IEvent[];
  liveEvents: IEvent[];
  eventsLoading: boolean;
  liveEventsLoading: boolean;
}
interface QueryObject {
  view: string;
  active: string;
}
function ScrollTop(props: ScrollTopButton) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function EventsDesktopView({
  events,
  liveEvents,
  eventsLoading,
  liveEventsLoading,
}: IEventsViewProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams()! as ReadonlyURLSearchParams & {
    size: number;
  };

  const [isMap, setIsMapOpen] = useQueryState('map');
  const [showMap, setShowMap] = useState(false);
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const handleAnimationComplete = () => {
    if (isMap) {
      setShowMap(true);
    } else {
      setShowMap(false);
    }
  };

  const handleQuery = (query: QueryObject) => {
    router.push(`${pathname}?${createQueryString(query.view, query.active)}`);
  };

  const onOpenMap = () => {
    setIsMapOpen('true');
    posthog.capture('show_map_clicked');
  };

  return (
    <Stack
      direction="row"
      maxWidth="100%"
      sx={{ px: 2, ...(isMap && { height: 'calc(100vh - 155px)', pr: 0 }) }}
    >
      <AnimatePresence>
        <m.div
          key="main-section"
          initial={{ width: '100%' }}
          animate={{ width: isMap ? '70%' : '100%' }}
          exit={{ width: '100%' }}
          transition={{ duration: 0.5 }}
          style={{ overflowY: isMap ? 'scroll' : 'unset' }}
        >
          <Stack pt={2} px={2}>
            <LiveEventsDesktop list={liveEvents} isLoading={liveEventsLoading} />

            <Stack direction="row" gap={1} alignItems="center" sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ color: (theme) => theme.palette.grey[50] }}>
                Events {searchParams.get('place') ? 'in' : ''}
              </Typography>
              <Typography variant="h6">{searchParams.get('place') ?? 'Near You'}</Typography>
            </Stack>

            <EventList events={events} isLoading={eventsLoading} />
          </Stack>
        </m.div>

        {isMap && (
          <m.div
            key="map-section"
            initial={{ width: 0 }}
            animate={{ width: '30%' }}
            exit={{ width: 0 }}
            onAnimationComplete={handleAnimationComplete}
            transition={{ duration: 0.5 }}
            style={{ overflow: 'hidden' }}
          >
            <Stack flexDirection="column" width="100%" height="100%">
              {showMap && <MapView settingQuery={handleQuery} />}
            </Stack>
          </m.div>
        )}
      </AnimatePresence>

      <ScrollTop>
        <Fab
          aria-label="scroll back to top"
          sx={{
            position: 'fixed',
            bottom: '10%',
            left: '50%',
            transform: 'translate(-50%, 5%)',
            borderRadius: 1,
            height: 2,
            width: 'fit-content',
            px: 1,
            py: 0,
          }}
          onClick={onOpenMap}
        >
          <Iconify icon="uil:map-pin" width={20} height={20} /> Map
        </Fab>
      </ScrollTop>
    </Stack>
  );
}
