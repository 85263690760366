'use client';

import dynamic from 'next/dynamic';
import { styled } from '@mui/material/styles';
import { MAPBOX_API } from 'src/config-global';
import { MapBoxProps } from '../../components/map';
import { IEvent } from '../../types';

const EventMap = dynamic(() => import('./event-map'));

export const THEMES = {
  streets: 'mapbox://styles/mapbox/streets-v11',
  outdoors: 'mapbox://styles/mapbox/outdoors-v11',
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v10',
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11',
};

export const baseSettings = {
  mapboxAccessToken: MAPBOX_API,
  minZoom: 1,
  hideScaleControl: true,
};

export const StyledMapContainer = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  // position: 'relative',
  '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
}));

// ----------------------------------------------------------------------

interface Props extends MapBoxProps {
  settingQuery?: Function;
  event?: IEvent;
  hideGallery?: boolean;
  hideGeolocateControl?: boolean;
  hideScaleControl?: boolean;
  sx?: any;
}

export default function MapView(props: Props) {
  const { settingQuery, event, hideGallery, sx } = props;

  return (
    <StyledMapContainer sx={{ ...sx }}>
      <EventMap
        {...baseSettings}
        event={event}
        mapStyle={THEMES.dark}
        settingQuery={settingQuery}
        hideGallery={hideGallery}
        {...props}
      />
    </StyledMapContainer>
  );
}
