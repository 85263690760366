import { IPriceFilter, PriceFilter } from '../models';

export function getPriceFilter(filter?: PriceFilter): IPriceFilter {
  switch (filter) {
    case PriceFilter.Free:
      return { free: true };
    case PriceFilter.Paid:
      return { paid: true };
    default:
      return {};
  }
}
