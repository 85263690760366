export enum DateFilter {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  ThisWeek = 'ThisWeek',
}
export interface Period {
  from?: string;
  to?: string;
}

export enum PriceFilter {
  Paid = 'paid',
  Free = 'free',
}

export interface IPriceFilter {
  paid?: boolean;
  free?: boolean;
}
