import useSWR from 'swr';

import { useMemo } from 'react';
// utils
import axios, { fetcher, endpoints } from 'src/utils/axios';
// types
import { isValidToken } from 'src/auth/context/jwt/utils';
import { ICreateEvent, IEvent, ISubmitEvent } from 'src/types/events';
import { CancelToken } from 'axios';

// ----------------------------------------------------------------------
const STORAGE_KEY = 'accessToken';

export async function createEvent(eventData: ICreateEvent) {
  /**
   * Work on server
   */
  const URL = endpoints.event.create;

  const { data } = await axios.post(URL, eventData);

  return { newEvent: data };
}

export async function submitEvent(eventData: ISubmitEvent) {
  /**
   * Work on server
   */
  const URL = endpoints.event.submit;

  const { data } = await axios.post(URL, eventData);

  return { newEvent: data };
}

export async function updateEvent(eventId: string, eventData: any) {
  /**
   * Work on server
   */
  const URL = endpoints.event.update(eventId);

  const data = await axios.post(URL, eventData);
  return { newEvent: data };
}

// ----------------------------------------------------------------------

export function useUpdateEvent(eventId: string, eventData: any) {
  const URL = endpoints.event.update(eventId);
  // @ts-ignore
  const { data, trigger, isLoading, error, isValidating } = useSWR(URL, fetcher);

  return { data, trigger };
}

// ----------------------------------------------------------------------

export async function searchEvents(query: any, cancelToken: CancelToken) {
  return axios.get(endpoints.event.list, { params: query, cancelToken });
}
export function useGetEvents(query: any) {
  const URL = query ? [endpoints.event.list, { params: { ...query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      events: (data?.results as IEvent[]) || [],
      eventsLoading: isLoading,
      eventsError: error,
      eventsValidating: isValidating,
      eventsEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export function useGetLiveEvents(query: any) {
  const URL = query ? [endpoints.event.live, { params: { ...query } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      liveEvents: (data?.results as IEvent[]) || [],
      liveEventsLoading: isLoading,
      liveEventsError: error,
      liveEventsValidating: isValidating,
      liveEventsEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetAttendedEvents() {
  const { data, isLoading, error, isValidating } = useSWR(endpoints.event.attended, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      events: (data?.results as IEvent[]) || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetSubscribedEvents() {
  const { data, isLoading, error, isValidating } = useSWR<IEvent[]>(
    endpoints.event.subscribedEvents,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const memoizedValue = useMemo(
    () => ({
      events: data || [],
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetEvent(id: string) {
  const accessToken = localStorage.getItem(STORAGE_KEY);
  const URL =
    accessToken && isValidToken(accessToken)
      ? endpoints.event.detail(id)
      : endpoints.event.detailPublic(id);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: false,
    revalidateOnFocus: true,
  });

  const memoizedValue = useMemo(
    () => ({
      event: data as IEvent,
      eventLoading: isLoading,
      eventError: error,
      eventValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
