// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line/text-max-line';
import { IEvent } from 'src/types/events';
import posthog from 'posthog-js';
import { getDefaultPicture, getPictureToDisplay } from '../../utils/picture';
import { fEventStartDate } from '../../utils/format-time';

type Props = {
  event: IEvent;
};

export default function EventItem({ event }: Props) {
  const { title, pictures, id, participants } = event;
  const router = useRouter();
  const pathname = usePathname();

  const eventDefaultPicture = event?.cover ?? getDefaultPicture(pictures);
  const renderAvatar = (
    <Stack direction="row" spacing={-2}>
      {participants.slice(0, 4).map((participant, index) => (
        <Avatar
          key={index}
          src={getPictureToDisplay(participant)}
          sx={{ width: 20, height: 20, ml: participants.length > 2 ? -0.5 : 0, zIndex: index + 1 }}
        />
      ))}
    </Stack>
  );

  const renderImages = (
    <Stack spacing={2} direction="row">
      <Stack flexGrow={1} sx={{ position: 'relative' }}>
        {participants.length > 0 && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              left: 7,
              px: 1,
              py: 1,
              zIndex: 9,
              borderRadius: 1,
              bottom: 7,
              backgroundColor: '#212121',
              position: 'absolute',
            }}
          >
            {renderAvatar}
          </Stack>
        )}
        {eventDefaultPicture && (
          <Image
            alt={eventDefaultPicture?.url}
            src={eventDefaultPicture?.url}
            sx={{ borderRadius: 0, height: 184, width: 1 }}
          />
        )}
      </Stack>
    </Stack>
  );

  const renderTexts = (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ p: 1 }}>
      <TextMaxLine
        variant="caption"
        sx={{
          component: 'span',
          color: 'text.primary',
          typography: 'subtitle1',
          maxWidth: '70%',
        }}
      >
        {title}
      </TextMaxLine>
      <Typography variant="date">
        {fEventStartDate(event?.startDate ?? event.date, event?.timezone)}
      </Typography>
    </Stack>
  );

  const onClickEvent = () => {
    router.push(`${pathname}event/${id}`);
    posthog.capture('event_page_opened', { eventId: id });
  };

  return (
    <Card
      onClick={onClickEvent}
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 0.5,
      }}
    >
      {renderImages}
      {renderTexts}
    </Card>
  );
}
