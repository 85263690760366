import { isEmpty } from 'lodash';
import { EventConnectMatch, IEventParticipant, Picture, User } from '../types';

export const getDefaultPicture = (pictures: Picture[]) => {
  if (!pictures) {
    return { url: '' };
  }
  return pictures?.find((picture) => picture.isDefault) ?? pictures[0];
};

export const extraParticipant = (participant: number) => {
  if (participant === 1) return '+ 1 is going';
  return `+ ${participant} are going`;
};

export const attendedParticipant = (participant: number) => `${participant} attended`;

export const getPictureToDisplay = (
  user: User | EventConnectMatch['user'] | IEventParticipant
): string => {
  if (user?.showAvatar || isEmpty(user?.pictures)) {
    return user?.avatar?.url as string;
  }

  return getDefaultPicture(user?.pictures).url;
};
