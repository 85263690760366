import { DateFilter, Period } from '../models';

export function getDateRange(filter?: DateFilter): Period {
  const today = new Date();
  const startOfDay = getStartOfDay(today);

  switch (filter) {
    case DateFilter.Today:
      return getTodayRange(today, startOfDay);
    case DateFilter.Tomorrow:
      return getTomorrowRange(today);
    case DateFilter.ThisWeek:
      return getThisWeekRange(today, startOfDay);
    default:
      return {};
  }
}

function getStartOfDay(date: Date): Date {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
}

function getTodayRange(today: Date, startOfDay: Date): Period {
  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);

  return { from: startOfDay.toISOString(), to: endOfDay.toISOString() };
}

function getTomorrowRange(today: Date): Period {
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const startOfTomorrow = getStartOfDay(tomorrow);
  const endOfTomorrow = new Date(tomorrow);
  endOfTomorrow.setHours(23, 59, 59, 999);
  return { from: startOfTomorrow.toISOString(), to: endOfTomorrow.toISOString() };
}

function getThisWeekRange(today: Date, startOfDay: Date): Period {
  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() + (7 - today.getDay()));
  endOfWeek.setHours(23, 59, 59, 999);
  return { from: startOfDay.toISOString(), to: endOfWeek.toISOString() };
}
